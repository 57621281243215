import React from 'react';
import { Header } from '../stories/Header';
//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import '../App.css';
import {useParams} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Comment, Button, Form, Placeholder } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { OwnerTag } from '../stories/OwnerTag';
import { useHistory } from "react-router-dom";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
export function House() {
  const { loginWithRedirect, logout, user } = useAuth0();
    const {house} = useParams()

  const [houseData, setHouseData] = useState(null)
  const history = useHistory();
  useEffect(() => {
    getData();
    async function getData() {
      try{
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT +'/house/'+house)
      

      const data = await response.json();
      
      setHouseData(data);
    } catch(err)  {
      history.push("/")
    }
    }
  }, [history, house]);

    function GenerateQuestions () {
    const questions = houseData.questions.map((question) => 
    
    <Comment>
      <Comment.Avatar src={question.profile} />
    <Comment.Content>
      <Comment.Author as='a'>{question.user}</Comment.Author>
      <Comment.Metadata>
        <div>{question.created_at}</div>
      </Comment.Metadata>
      <Comment.Text>{question.text}</Comment.Text>
      <Comment.Actions>
        <Comment.Action>Reply</Comment.Action>
      </Comment.Actions>
    </Comment.Content></Comment>
    
    )
    return questions
    } 
    /*{houseData && (
      GenerateQuestions())} 
      {houseData ? (
      ) : {}}
      
      */

    return (

        <div>
        <Header user={user} size="halfscreen" onLogin={() => loginWithRedirect()} onLogout={() => logout({ returnTo: window.location.origin })} onCreateAccount={() => loginWithRedirect()} />
        <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="Header" />
        </PlasmicRootProvider>
        {houseData ? (
          <>
         <div class="heroimage" style={{height:'545px',backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("${houseData.mainphoto}")`}}/>
         </>
         ) : (  <Placeholder>
          <Placeholder.Header image/>
        </Placeholder>)}
      <body class= "thinsection">
        <div>
      {houseData ? (
      
         <div
          class="HouseContainer"
        >
          <OwnerTag
            name={houseData.owner.name}
            id={houseData.owner.id}
            image={houseData.owner.profile}
          />

          <div class="CardLine">
            <div class="houseName">{houseData.name}</div>
            <div class="placeName">{houseData.location}</div>
          </div>

          <div class="bAB">
            {houseData.basicfeatures.msq}msq | {houseData.basicfeatures.beds} Beds | {houseData.basicfeatures.bath} Bath
          </div>
          <div class="ChipBag">{houseData.tags}</div>
        </div>

         ) : (  <Placeholder>
          <Placeholder.Header image/>
        </Placeholder>)}
          <div>
        <Comment.Group>

        {houseData ? (
         GenerateQuestions()
         ) : (  <Placeholder>
          <Placeholder.Header image/>
        </Placeholder>) }

    <Form reply>
      <Form.TextArea />
      <Button content='Add Reply' labelPosition='left' icon='edit' primary />
    </Form>
  </Comment.Group>
          </div>
          </div>

      </body>
        <PlasmicComponent component="Footer"/>
      </div>

      
    )
  }


