import React from 'react';
import PropTypes from 'prop-types';
import './chip.css';

/**
 * Primary UI component for viewing houses
 */
export  const Chip = ({ label,chipColor}) => {
  return (
    <div class="Chip rounded">
    <div class="ChipText" style={chipColor && { chipColor }}>{label}</div>
  </div>


  );
};

Chip.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
   label: PropTypes.string.isRequired,
   chipColor: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
};

Chip.defaultProps = {
  label: "Category",
  chipColor: '#B6A99A'
};

