import './App.css';
import './global.css';
import React from 'react';
import {Home} from "./pages/home.js"
import { House } from './pages/house';
import { Account } from './pages/account';
import { Discover } from './pages/discover';
import { Builder } from './pages/builder';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import { PlasmicRootProvider, PlasmicCanvasHost } from '@plasmicapp/loader-react';
import { PLASMIC } from './plasmic-init';

function App() {

  




  return (
    <PlasmicRootProvider loader={PLASMIC} >
    <Router>
      <div>
        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/plasmic-host" render={() => <PlasmicCanvasHost />} />
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/discover">
            <Discover />
          </Route>
          <Route path="/builder/:id">
            <Builder />
            </Route>
          <Route path="/houses/:house">
          <House />
        </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
    </PlasmicRootProvider>
  );
}







export default App;
