import React from 'react';
//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectHits, Configure, ClearRefinements, RangeInput, CurrentRefinements, Pagination } from 'react-instantsearch-dom';
import { HouseCard } from '../stories/HouseCard';
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
const searchClient = algoliasearch('DK67BAAZJ6', '7e4765a8fb5552da753462c97ac9190d');


export function Discover() {

    return (
        
        
        <div>
         


        
        <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="Header" componentProps={{variants: {light: true}}}/>
        </PlasmicRootProvider>
      
        <div class="heroimage" style={{height:'400px',backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`}}>
    
        <div class= "herobox" >
          <text class="herotext noMobile">Discover</text>
          
        </div>

        </div>
        
        <body class="houseSection">
        <InstantSearch searchClient={searchClient} indexName="prod_City">
          
        <Configure clickAnalytics />
    <SearchBox />
    <div class="Searchbox"><ClearRefinements/><CurrentRefinements/> <RangeInput/></div>
    
    <MyHits />
    <div class="centrebox">
    <Pagination />
    </div>
  </InstantSearch>
        
        
      </body>

        <PlasmicComponent component="Footer"/>

      </div>
    )
  }

  const MyHits = connectHits(({ hits }) => {
    const hs = hits.map(hit => <div id={hit.id}><HouseCard id={hit.id} houseName={hit.name} placeName={hit.location} msq={hit.basicfeatures.msq} beds={hit.basicfeatures.beds} baths={hit.basicfeatures.bath} ownerID={hit.owner.id} ownerName={hit.owner.name} userphoto={hit.owner.photoID} housePhoto={hit.mainphoto}/></div>);
    return <div id="hits" class="StreetBlock">{hs}</div>;
  });


