
//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import { useAuth0 } from "@auth0/auth0-react";
import { HouseList } from "../stories/HouseList";
import { Placeholder, Icon, Segment, Dimmer, Loader, Image } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';

var housedata = [
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
  {
    owner: {},
    loading: true,
  },
];
export function Account() {
  const {
    loginWithRedirect,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ returnTo: window.location.origin });
  }
  const [accountData, setaccountData] = useState(null);
  useEffect(() => {
    console.log("Getting Data")
    getData()
    
    async function getData() {
      console.log("got data")

      
      if (isLoading === true && isAuthenticated === false){
     
      } else {
        // CODE GOES IN HERE
      
        console.log("loading is true")


      if (isAuthenticated) {
        



        const token = await getAccessTokenSilently({
          audience:"Hosted on AWS"
        });
        console.log("got token")
  



      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/account",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const data = await response.json();
  
      setaccountData(data);




      } else {

        console.log("Uauth")

      }
    
    }
    }
    


  }, [getAccessTokenSilently, isAuthenticated, isLoading]);


  async function createhouse () {

    const token = await getAccessTokenSilently({
      audience:"Hosted on AWS"
    });
    console.log("got token")

    const response = await fetch(
      process.env.REACT_APP_API_ENDPOINT + "/account",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    setaccountData(data)
  }
  return (
    <div>
      <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="Header" componentProps={{variants: {light: true}}}/>
        </PlasmicRootProvider>

      <div
        class="heroimage"
        style={{
          height: "500px",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`,
        }}
      >
        <div class="herobox">
          {isLoading ? (
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="long" />
                <Placeholder.Line length="short" />
              </Placeholder.Header>
            </Placeholder>
          ) : (
            <>
              <text class="herotext">Welcome</text>
              <text class="tagline">Hi {user.nickname}</text>
            </>
          )}
        </div>
      </div>

      <body class="houseSection">
      {accountData ? (
        <>
        <Segment onClick={createhouse}>
        <text>Create a new house</text>
        <Icon name="arrow circle right" ></Icon>
          
        </Segment>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
        </>
         ) : (
          <>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
        </>
          )}
        {accountData ? (
          <>
          <text class="listheader">Your <text style={{color:'B6A99A'}}>Houses</text></text>
          {accountData.houses.length > 0 ? (<HouseList housejson={accountData.houses}></HouseList>) : (<div>No Houses Here? you should create one!</div>)}
          <text class="listheader">Your <text style={{color:'B6A99A'}}>Favs</text></text>
          {accountData.favs.length > 0 ? (<HouseList housejson={accountData.favs}></HouseList>) : (<div>No Favs go favorite some!</div>)}
            </>
          ) : (
            <>
            <text class="listheader">Your <text style={{color:'B6A99A'}}>Houses</text></text>
              <HouseList housejson={housedata}></HouseList>
              <text class="listheader">Your <text style={{color:'B6A99A'}}>Favs</text></text>
              <HouseList housejson={housedata}></HouseList>
            </>
          )}
      </body>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="Footer"/>
      </PlasmicRootProvider>
    </div>
  );
}
