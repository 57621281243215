import React from 'react';
import PropTypes from 'prop-types';
import './HouseList.css';
import { HouseCard } from './HouseCard.jsx';
/**
 * Primary UI component for viewing houses
 */


export  const HouseList = ({ housejson}) => {
  const cityblock = housejson.map((hh) => 

  <HouseCard Class="CardListWrapper" houseName={hh.name} placeName={hh.location} msq={hh.msq} beds={hh.beds} baths={hh.baths} tags={hh.tags} id={hh.id} loading={hh.loading} housePhoto={hh.housePhoto} ownerID={hh.owner.id} ownerName={hh.owner.name} ownerphoto={hh.owner.photo}></HouseCard>
    );
  return (
    <div Class="StreetBlock">
      {cityblock}
    </div>

  );
};


HouseList.propTypes = {
  /**
   * name of the house
   */
   houses: PropTypes.object
     
};

HouseList.defaultProps = {
};

