
//import {OnLogin, OnLogout,OnCreateAccount} from '../shared/global.js'
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";
import { Placeholder, Segment, Dimmer, Loader, Image } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';

export function Builder() {
  const {
    loginWithRedirect,
    user,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently
  } = useAuth0();
   const {id} = useParams()
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ returnTo: window.location.origin });
  }
  const [accountData, setaccountData] = useState(null);
  useEffect(() => {
    console.log("Getting Data")
    getData()
    
    async function getData() {
      console.log("got data")

      
      if (isLoading === true && isAuthenticated === false){
     
      } else {
        // CODE GOES IN HERE
      
        console.log("loading is true")


      if (isAuthenticated) {
        



        const token = await getAccessTokenSilently({
          audience:"Hosted on AWS"
        });
        console.log("got token")
      setaccountData(token);
  



      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT + "/house/" + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const data = await response.json();
  
      setaccountData(data);




      } else {

        console.log("Uauth")

      }
    
    }
    }
    


  }, [getAccessTokenSilently, id, isAuthenticated, isLoading]);


  return (
    <div>
      <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="Header" />
        </PlasmicRootProvider>
      

      <div
        class="heroimage"
        style={{
          height: "500px",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`,
        }}
      >
        <div class="herobox">
          {isLoading ? (
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="long" />
                <Placeholder.Line length="short" />
              </Placeholder.Header>
            </Placeholder>
          ) : (
            <>
              <text class="herotext">Welcome</text>
              <text class="tagline">Hi {user.nickname}</text>
            </>
          )}
        </div>
      </div>

      <body class="houseSection">
        {accountData}
        <Segment>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>

          <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
        </Segment>
      </body>
      <PlasmicRootProvider loader={PLASMIC}>
        <PlasmicComponent component="Footer"/>
      </PlasmicRootProvider>
    </div>
  );
}
