import React from "react";
import PropTypes from "prop-types";

import { Button } from "./Button";
import "./header.css";
import { useHistory } from "react-router-dom";

export const Header = ({ user, onLogin, onLogout, onCreateAccount, size, LeadColor }) => {
const history = useHistory();

  return(
  <header>
    <div className={`header wrapper-${size}`}>
      <div onClick={() => history.push("/")}>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="32.000000pt"
          height="32.000000pt"
          viewBox="0 0 1280.000000 640.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
            fill={LeadColor}
            stroke="none"
          >
            <path
              d="M806 6019 c-228 -31 -480 -135 -669 -274 -85 -63 -121 -104 -134
              -154 -7 -29 17 -103 66 -196 55 -103 225 -398 263 -453 46 -68 173 -197 244
              -248 296 -212 700 -236 1149 -69 313 116 625 349 625 467 0 99 -281 465 -475
              619 -181 143 -411 251 -628 295 -90 18 -350 26 -441 13z"
            />
            <path
              d="M4540 5704 c-92 -32 -487 -227 -565 -279 -181 -120 -315 -296 -384
              -502 -79 -235 -65 -575 35 -880 39 -120 122 -299 178 -386 44 -70 46 -90 5
              -51 -56 52 -346 400 -655 784 -462 574 -785 942 -875 994 l-32 19 6 -36 c14
              -87 172 -353 508 -855 152 -227 395 -574 537 -768 45 -61 62 -94 48 -94 -2 0
              -35 12 -72 26 -385 145 -762 162 -1101 49 -443 -148 -768 -500 -888 -961 -62
              -238 -38 -265 375 -429 305 -121 375 -138 555 -137 197 1 347 45 521 153 111
              69 295 249 386 379 160 227 282 524 303 738 4 44 12 72 19 72 6 -1 36 -33 66
              -72 175 -228 365 -387 893 -751 495 -341 740 -538 723 -582 -15 -40 -175 -86
              -451 -130 -88 -14 -189 -33 -225 -42 -202 -50 -564 -215 -1184 -540 -649 -341
              -1051 -508 -1461 -609 -249 -61 -385 -80 -657 -94 -127 -6 -209 -14 -231 -23
              -102 -43 -101 -157 2 -250 80 -72 151 -90 247 -62 203 59 800 283 1694 635
              1530 603 2118 810 2760 970 222 55 233 57 820 136 398 53 595 127 784 291 176
              154 251 205 406 283 105 53 324 135 490 184 396 116 805 195 1735 336 826 124
              1101 176 1413 266 84 24 285 71 445 104 588 121 854 210 991 331 43 38 96 129
              96 164 0 45 -63 150 -130 216 l-69 68 -38 -13 c-21 -8 -110 -42 -198 -76 -756
              -295 -1601 -558 -3400 -1060 -1071 -299 -1617 -459 -2095 -615 -405 -132 -601
              -168 -920 -169 -158 0 -244 4 -324 17 -548 88 -1016 350 -1554 872 -57 55
              -109 109 -114 119 -9 17 -8 18 13 12 66 -21 77 -19 201 42 273 133 495 320
              649 545 232 338 303 731 204 1121 -45 178 -158 404 -250 503 l-51 53 -69 0
              c-39 -1 -90 -8 -115 -16z"
            />
          </g>
        </svg>
        <text class="brandedTitle" style={{color: LeadColor}}>twigged</text>
      </div>
      <div>
      <Button size="small" onClick={() => history.push("/discover")} label="Discover" />
        {user ? (
          <>
          <Button size="small"onClick={onLogout} label="Log out" />
          
          <Button size="small" onClick={() => history.push("/account")} label="Account" primary/>
          </>
        ) : (
          <>
            <Button size="small" onClick={onLogin} label="Log in" />
            <Button
              primary
              size="small"
              onClick={onCreateAccount}
              label="Sign up"
            />
          </>
        )}
        
      </div>
    </div>
  </header>
  );
}

Header.propTypes = {
  user: PropTypes.shape({}),
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
  LeadColor: PropTypes.string,
  size: PropTypes.oneOf(['fullscreen', 'halfscreen'])
};

Header.defaultProps = {
  user: null,
  size: 'fullscreen',
  LeadColor: '#B6A99A'
};
