import React from 'react';
import PropTypes from 'prop-types';
import './OwnerTag.css';
import { Image } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
/**
 * Primary UI component for viewing houses
 */
export  const OwnerTag = ({ name,image, id}) => {
  const history = useHistory();
  return (
    <div class="owner" onClick={() => history.push("/user/" + id)}>
    <Image src={image} avatar />
    <text class="ownertext">By {name}</text>
  </div>


  );
};

OwnerTag.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
   name: PropTypes.string.isRequired,
   image: PropTypes.string.isRequired,
   id: PropTypes.string,
  /**
   * Optional click handler
   */
};

OwnerTag.defaultProps = {
  name: "Lisa Booth",
  image: 'https://react.semantic-ui.com/images/wireframe/square-image.png'
};

