import React from "react";
import PropTypes from "prop-types";
import "./HouseCard.css";
import { Chip } from "./chip";
import { useHistory } from "react-router-dom";
import { Placeholder } from "semantic-ui-react";
import { PlasmicComponent } from "@plasmicapp/loader-react";



/**
 * Primary UI component for viewing houses
 */
export const HouseCard = ({
  houseName,
  placeName,
  msq,
  beds,
  baths,
  tags,
  id,
  nameOwner,
  ownerID,
  loading,
  userphoto,
  housePhoto
}) => {

  if (housePhoto == null) {
    housePhoto = "https://worldwellnessgroup.org.au/wp-content/uploads/2020/07/placeholder.png"
  }

console.log(placeName)
    var taglist = tags.split(",");
    
    var chips = taglist.map((d) => <Chip label={d} chipColor="#b6a99a"></Chip>);
    if (chips === <Chip label="" chipColor="#b6a99a"></Chip>) {
      chips =""
    }
  const history = useHistory();
  const isloading = loading;
  return (
    <div>
      {isloading ? (
        <div class="HouseContainer">
          <Placeholder class="HouseContainer">
            <Placeholder.Image square />

            <Placeholder.Header>
              <Placeholder.Line />

              <Placeholder.Line length="short" />
              <div style={{ float: "left" }}>
                <Placeholder.Line length="very short" />
                <Placeholder.Line length="very short" />
              </div>
            </Placeholder.Header>
          </Placeholder>
        </div>
      ) : (
        <div class="HouseContainer">
        <PlasmicComponent 
  component="House Card"
  componentProps={{
        title: houseName,
        picture: housePhoto,
        location: placeName,
        ownerPicture: userphoto,
        detail: `${msq} msq | ${beds} Beds | ${baths} Baths`,
        houseOwner: {
          ownerName:`By ${nameOwner}`,
        },
        onClick: () => {history.push("/houses/"+id)},
        onFavorite: () => {console.log("Button was favorited");},
        onOwner: () => {history.pushlog("/people/"+ownerID);}
      }
  }
  
      />
      </div>
      )}
    </div>
  );
};

HouseCard.propTypes = {
  /**
   * name of the house
   */
  houseName: PropTypes.string,
  /**
   * place name text
   */
  placeName: PropTypes.string,
  /**
   * metres sqaured value
   */
  msq: PropTypes.number,
  /**
   * house beds
   */
  beds: PropTypes.number,
  /**
   * house baths
   */
  baths: PropTypes.number,
  /**
   * tags, comma seperated
   */
  tags: PropTypes.string,

  loading: PropTypes.bool,
};

HouseCard.defaultProps = {
  houseName: null,
  msq: 0,
  beds: 0,
  baths: 0,
  loading: false,
  tags: ""
};
