import { initPlasmicLoader } from "@plasmicapp/loader-react";

import { HouseCard } from "./stories/HouseCard";

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "3EFtY8xZkGiVrpYgMkfarh",  // ID of a project you are using
      token: "SwDpYJFNKQXUGSd2fnSk99RArz1l2SYcIaUILLPIVfw374dGrK2dgGR1TeNNdgIAVCBIVJ4SeQBLX6puVQ"  // API token for that project
    }
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: process.env.REACT_APP_PLASMIC,
})

PLASMIC.registerComponent(HouseCard, {
  name: 'HouseCard',
  props: {
    houseName: 'string',
    placeName: 'number',
    msq: "number",
    beds: "number",
    baths: "number",
    tags: "string",
    id: "string",
    ownerName: "string",
    ownerID: "string",
    loading: "string",
    userphoto: "string",
    housePhoto: "string"
  }
});