import React from 'react';


import { PlasmicComponent } from '@plasmicapp/loader-react';
import { HouseCard } from '../stories/HouseCard';
var housedata = [
  {"name": "The Sandhill", "location": "Kyabram, VIC", "msq": 242, "beds": 6, "baths": 2, "tags": "Family Home, Off-Grid, Creator", "id":"12345678910", "housePhoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", owner:{}},
  {"name": "The Van", "location": "Kyabram, VIC", "msq": 242, "beds": 6, "baths": 2, "tags": "Family Home, Off-Grid, Creator", "id":"12345678910",  "housePhoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", owner:{}},
  {"name": "The Van", "location": "Kyabram, VIC", "msq": 242, "beds": 6, "baths": 2, "tags": "Family Home, Off-Grid, Creator", "id":"12345678910",  "housePhoto": "https://images.squarespace-cdn.com/content/v1/5eccadbaa9658a4c95a055f3/1590549019887-7L2PJUZQ7R5A0HXDG6VJ/IMG_0673.jpg?format=1000w", owner:{}}
]

export function Home() {
    return (
      <>
        
        <PlasmicComponent component="Home" componentProps={{
          fav1: GetFavs(),
          fav2: GetFavs()
        }

        }/>

        {/*
        <div>
        <Header user={user} LeadColor="#EFEAE5" onLogin={() => loginWithRedirect()} onLogout={() => logout({ returnTo: window.location.origin })} onCreateAccount={() => loginWithRedirect()} />
        <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="Header" componentProps={{variants: {light: true}}}/>
        </PlasmicRootProvider>
      
        <div class="heroimage" style={{height:'745px',backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2) ), url("https://scontent-syd2-1.xx.fbcdn.net/v/t1.6435-9/196315106_2917315145153717_9059184619568423288_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=a26aad&_nc_ohc=lzlxg4xAdTYAX-8gyrN&_nc_ht=scontent-syd2-1.xx&oh=beebe566d488a5db013b7743fe73a638&oe=61859221")`}}>
    
        <div class= "herobox" >
          <text class="herotext">homes for the planet</text>
          <text class="tagline noMobile">FIND A DESIGN OR SHARE YOUR CASTLE</text>
          
         
        </div>

        </div>
        
        <div class="houseSection">
          <text class="listheader">Discover <text style={{color:'B6A99A'}}>off grid</text></text>
         <HouseList housejson={housedata}></HouseList>
         <PlasmicRootProvider loader={PLASMIC}>
      <PlasmicComponent component="FeatureItem"/>
      </PlasmicRootProvider>
        
      </div>
      </div>
      */}
      </>
    )
  }


 function GetFavs() {

    const hs = housedata.map(hit => <div id={hit.id}><HouseCard id={hit.id} houseName={hit.name} placeName={hit.location} msq={hit.msq} beds={hit.beds} baths={hit.bath} ownerID={hit.id} ownerName={hit.name} userphoto={hit.photoID} housePhoto={hit.housePhoto}/></div>);
    return hs;
 }
